import MyCard from "@common-utils/MyCard"
import React, { useState } from "react"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import axios, { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import {
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_CONSULTANT_CONTRAT_CDD,
     CONST_CONSULTANT_CONTRAT_CDI,
     CONST_CONSULTANT_CONTRAT_CDIC,
     CONST_CONSULTANT_STATUT_AGENT_MAITRISE,
     CONST_CONSULTANT_STATUT_CADRE,
     CONST_CONSULTANT_STATUT_ETAM,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_FOURNISSEUR_TYPE_FREELANCE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE,
     CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { toast } from "react-toastify"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ENTITES_ENDPOINT, CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT, CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT } from "../../../../endpoints"
import MyModal from "@common-utils/MyModal"
import AddFournisseurForm from "../../../utils/core/_AddFournisseurForm"
import AddResponsableFournisseurForm from "../../../utils/_AddResponsableFournisseurForm"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useNavigate } from "react-router-dom"

const DemandeEmbaucheStart = () => {
     const navigate = useNavigate()

     const [isAddingFournisseur, setIsAddingFournisseur] = useState<boolean>(false)
     const [isAddingFournisseurResp, setIsAddingFournisseurResp] = useState<boolean>(false)

     const schema = Yup.object().shape({
          sousTraitantOuSalarie: Yup.string().label(`"Catégorie"`).required(),
          typeSousTraitant: Yup.string()
               .label(`"Type sous-traitant"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          fournisseur: Yup.number()
               .label(`"Fournisseur"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          fournisseurResp: Yup.number()
               .label(`"Responsable fournisseur"`)
               .when("sousTraitantOuSalarie", {
                    is: "ST",
                    then: schema => schema.required(),
               }),
          saisieManuelleOuAPartirCandidatExistant: Yup.string()
               .label(`"Champ"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string) =>
                         (sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE",
                    then: schema => schema.required(),
               }),
          saisieManuelleCivilite: Yup.string()
               .label(`"Civilité"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleNom: Yup.string()
               .label(`"Nom"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuellePrenom: Yup.string()
               .label(`"Prénom"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleEmail: Yup.string()
               .label(`"Email"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleEntite: Yup.string()
               .label(`"Entité"`)
               .when(["sousTraitantOuSalarie", "typeSousTraitant", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, typeSousTraitant: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         ((sousTraitantOuSalarie === "ST" && typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) || sousTraitantOuSalarie === "SALARIE") &&
                         saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleContrat: Yup.string()
               .label(`"Contrat"`)
               .when(["sousTraitantOuSalarie", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         sousTraitantOuSalarie === "SALARIE" && saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
          saisieManuelleStatut: Yup.string()
               .label(`"Contrat"`)
               .when(["sousTraitantOuSalarie", "saisieManuelleOuAPartirCandidatExistant"], {
                    is: (sousTraitantOuSalarie: string, saisieManuelleOuAPartirCandidatExistant: string) =>
                         sousTraitantOuSalarie === "SALARIE" && saisieManuelleOuAPartirCandidatExistant,
                    then: schema => schema.required(),
               }),
     })
     const initialValues: IDemandeEmbaucheStartRequestModel = {}

     function handleSubmit(values: IDemandeEmbaucheStartRequestModel, helpers: FormikHelpers<IDemandeEmbaucheStartRequestModel>) {
          helpers.setStatus(null)

          axios.post("-")
               .then(r => {
                    // callBackOnSubmit(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                         <MyCard.Body>
                              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                   {helpers => {
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  {/* Field salarié / ST */}
                                                  <>
                                                       <div className={"fs-3 fw-bold required"}>Choisissez entre salarié et sous traitant</div>
                                                       <div className="separator separator-dashed mt-2 mb-4" />
                                                       <div className="d-flex flex-column gap-4">
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"sousTraitantOuSalarie"}
                                                                 value={"SALARIE"}
                                                                 description={"CDI C, CDI, CDD"}
                                                                 isChecked={helpers.values.sousTraitantOuSalarie === "SALARIE"}
                                                                 label={"SALARIÉ"}
                                                                 iconFontAwesomeClassName={"user-tie"}
                                                                 color={"dark"}
                                                                 isInvalid={!!helpers.errors.sousTraitantOuSalarie}
                                                                 onChange={val => helpers.setFieldValue("sousTraitantOuSalarie", val)}
                                                            />
                                                            <MyRadioWithLabelAndDescriptionField
                                                                 name={"sousTraitantOuSalarie"}
                                                                 value={"ST"}
                                                                 description={"Freelance, portage salariale ou société de service"}
                                                                 isChecked={helpers.values.sousTraitantOuSalarie === "ST"}
                                                                 label={"SOUS-TRAITANT"}
                                                                 iconFontAwesomeClassName={"user-astronaut"}
                                                                 color={"dark"}
                                                                 isInvalid={!!helpers.errors.sousTraitantOuSalarie}
                                                                 onChange={val => {
                                                                      helpers.setFieldValue("sousTraitantOuSalarie", val).then(() => {
                                                                           helpers.setFieldValue("typeSousTraitant", undefined).then()
                                                                           helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", undefined).then()
                                                                      })
                                                                 }}
                                                            />
                                                       </div>
                                                       {helpers.errors.sousTraitantOuSalarie && <div className={"text-danger mt-2"}>{helpers.errors.sousTraitantOuSalarie}</div>}
                                                  </>

                                                  {/* Cas Salarié*/}
                                                  {/* From candidat or from scratch */}
                                                  {helpers.values.sousTraitantOuSalarie === "SALARIE" && <></>}

                                                  {/* Cas Sous-traitant*/}
                                                  {helpers.values.sousTraitantOuSalarie === "ST" && (
                                                       <>
                                                            {/* Type de sous traitance */}
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>Choisissez le type de sous-traitance</div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />
                                                                 <div className="d-flex flex-column gap-4 mb-4">
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_FREELANCE}
                                                                           label={"Freelance"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE}
                                                                           iconFontAwesomeClassName={"building-user"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => {
                                                                                helpers.setFieldValue("typeSousTraitant", val).then(() => {
                                                                                     helpers.setFieldValue("fournisseur", undefined).then()
                                                                                     helpers.setFieldValue("fournisseurResp", undefined).then()
                                                                                     helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", undefined).then()
                                                                                })
                                                                           }}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_PORTAGE}
                                                                           label={"Société de portage"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_PORTAGE}
                                                                           iconFontAwesomeClassName={"building"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => helpers.setFieldValue("typeSousTraitant", val)}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"typeSousTraitant"}
                                                                           value={CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE}
                                                                           label={"Société de service"}
                                                                           isChecked={helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE}
                                                                           iconFontAwesomeClassName={"building"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.typeSousTraitant}
                                                                           onChange={val => helpers.setFieldValue("typeSousTraitant", val)}
                                                                      />
                                                                 </div>
                                                                 {helpers.errors.typeSousTraitant && <div className={"text-danger mt-2"}>{helpers.errors.typeSousTraitant}</div>}
                                                            </div>

                                                            {/* Fournisseur & contact fournisseur */}
                                                            {helpers.values.typeSousTraitant && (
                                                                 <div className={"mt-10"}>
                                                                      <div className={"fs-3 fw-bold required"}>Choisissez le fournisseur</div>
                                                                      <div className="separator separator-dashed mt-2 mb-4" />
                                                                      <div className="row mb-5">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Fournisseur</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectGetAllDataFromServer
                                                                                     name={"fournisseur"}
                                                                                     value={helpers.values.fournisseur}
                                                                                     url={CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT}
                                                                                     method={"GET"}
                                                                                     payload={(() => {
                                                                                          if (helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) {
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_FREELANCE] }
                                                                                          } else if (helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_PORTAGE) {
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE] }
                                                                                          } else {
                                                                                               // Société de service
                                                                                               return { types: [CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE] }
                                                                                          }
                                                                                     })()}
                                                                                     isInvalid={!!helpers.errors.fournisseur}
                                                                                     onChange={val => helpers.setFieldValue("fournisseur", val)}
                                                                                />
                                                                                <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                     {helpers.errors.fournisseur && (
                                                                                          <div className={"text-danger"}>{helpers.errors.fournisseur}</div>
                                                                                     )}
                                                                                     <div
                                                                                          className="text-primary fw-bold cursor-pointer"
                                                                                          onClick={() => setIsAddingFournisseur(true)}
                                                                                     >
                                                                                          Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>

                                                                      {helpers.values.fournisseur && (
                                                                           <div className="row mb-5">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contact fournisseur</label>

                                                                                <div className="col-lg-8">
                                                                                     <MySelectGetAllDataFromServer
                                                                                          name={"fournisseurResp"}
                                                                                          value={helpers.values.fournisseurResp}
                                                                                          url={CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT}
                                                                                          method={"GET"}
                                                                                          payload={{ fournisseur: helpers.values.fournisseur }}
                                                                                          isInvalid={!!helpers.errors.fournisseurResp}
                                                                                          onChange={val => helpers.setFieldValue("fournisseurResp", val)}
                                                                                     />
                                                                                     <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                          {helpers.errors.fournisseurResp && (
                                                                                               <div className={"text-danger"}>{helpers.errors.fournisseurResp}</div>
                                                                                          )}
                                                                                          <div
                                                                                               className="text-primary fw-bold cursor-pointer"
                                                                                               onClick={() => setIsAddingFournisseurResp(true)}
                                                                                          >
                                                                                               Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                          </div>
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      )}
                                                                 </div>
                                                            )}
                                                       </>
                                                  )}

                                                  {/* Cas salarié or sous traitant freelance */}
                                                  {(helpers.values.sousTraitantOuSalarie === "SALARIE" ||
                                                       (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                            helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                            helpers.values.fournisseur &&
                                                            helpers.values.fournisseurResp)) && (
                                                       <>
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>Saisie manuelle ou partir d’un candidat existant</div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />
                                                                 <div className="d-flex flex-column gap-4">
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"saisieManuelleOuAPartirCandidatExistant"}
                                                                           value={"EXISTANT"}
                                                                           label={"Candidat existant"}
                                                                           description={"Saisissez les informations à partir d'un profil candidat existant"}
                                                                           isChecked={helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT"}
                                                                           iconFontAwesomeClassName={"user-check"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.saisieManuelleOuAPartirCandidatExistant}
                                                                           onChange={val => helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", val)}
                                                                      />
                                                                      <MyRadioWithLabelAndDescriptionField
                                                                           name={"saisieManuelleOuAPartirCandidatExistant"}
                                                                           value={"MANUELLE"}
                                                                           label={"Saisie manuelle"}
                                                                           description={"Saisissez les informations à partir de zéro"}
                                                                           isChecked={helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE"}
                                                                           iconFontAwesomeClassName={"file-alt"}
                                                                           color={"dark"}
                                                                           isInvalid={!!helpers.errors.saisieManuelleOuAPartirCandidatExistant}
                                                                           onChange={val => helpers.setFieldValue("saisieManuelleOuAPartirCandidatExistant", val)}
                                                                      />
                                                                 </div>
                                                                 {helpers.errors.saisieManuelleOuAPartirCandidatExistant && (
                                                                      <div className={"text-danger mt-2"}>{helpers.errors.saisieManuelleOuAPartirCandidatExistant}</div>
                                                                 )}
                                                            </div>
                                                       </>
                                                  )}

                                                  {/* Saisir les info du consultant (Salarié avec saisie manuelle / ST freelance + saisie manuelle + fournisseur / ST non freelance + fournisseur + fournisseeur resp )*/}
                                                  {
                                                       /* Salarié avec saisie manuelle */
                                                       ((helpers.values.sousTraitantOuSalarie === "SALARIE" &&
                                                            helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                            (((helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                 helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                 helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                 (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                      helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE)) &&
                                                                 helpers.values.fournisseur &&
                                                                 helpers.values.fournisseurResp)) && (
                                                            <div className={"mt-10"}>
                                                                 <div className={"fs-3 fw-bold required"}>Informations du futur collaborateur</div>
                                                                 <div className="separator separator-dashed mt-2 mb-4" />

                                                                 {/* Civilité */}
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>
                                                                      <div className="col-lg-8">
                                                                           <MySelectField
                                                                                name={"saisieManuelleCivilite"}
                                                                                options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                                                value={helpers.values.saisieManuelleCivilite}
                                                                                isInvalid={!!helpers.errors.saisieManuelleCivilite}
                                                                                onChange={val => helpers.setFieldValue("saisieManuelleCivilite", val)}
                                                                           />
                                                                           {helpers.errors.saisieManuelleCivilite && (
                                                                                <div className={"text-danger"}>{helpers.errors.saisieManuelleCivilite}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Nom & Prénom*/}
                                                                 <div className={"row mb-5"}>
                                                                      {/* Nom & prénom */}
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom NOM</label>

                                                                      <div className="col-lg-8">
                                                                           <div className="row">
                                                                                <div className="col-lg-6 mb-2 mb-lg-0">
                                                                                     <MyInputField
                                                                                          name={"saisieManuellePrenom"}
                                                                                          value={helpers.values.saisieManuellePrenom}
                                                                                          isInvalid={!!helpers.errors.saisieManuellePrenom}
                                                                                          placeholder={"Prénom"}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuellePrenom", val)}
                                                                                     />
                                                                                     {helpers.errors.saisieManuellePrenom && (
                                                                                          <div className={"text-danger"}>{helpers.errors.saisieManuellePrenom}</div>
                                                                                     )}
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                     <MyInputField
                                                                                          name={"saisieManuelleNom"}
                                                                                          value={helpers.values.saisieManuelleNom}
                                                                                          isInvalid={!!helpers.errors.saisieManuelleNom}
                                                                                          placeholder={"Nom"}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuelleNom", val)}
                                                                                     />
                                                                                     {helpers.errors.saisieManuelleNom && (
                                                                                          <div className={"text-danger"}>{helpers.errors.saisieManuelleNom}</div>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>

                                                                 {/* Email (uniquement salarié et ST freelance) */}
                                                                 {(helpers.values.sousTraitantOuSalarie === "SALARIE" ||
                                                                      helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE) && (
                                                                      <div className="row mb-5">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>
                                                                           <div className="col-lg-8">
                                                                                <MyInputField
                                                                                     name={"saisieManuelleEmail"}
                                                                                     value={helpers.values.saisieManuelleEmail}
                                                                                     isInvalid={!!helpers.errors.saisieManuelleEmail}
                                                                                     placeholder={"Email"}
                                                                                     onChange={value => helpers.setFieldValue("saisieManuelleEmail", value)}
                                                                                />
                                                                                {helpers.errors.saisieManuelleEmail && (
                                                                                     <div className="text-danger">{helpers.errors.saisieManuelleEmail}</div>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 )}

                                                                 {/* Field entite */}
                                                                 <div className="row mb-5">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                                                      <div className="col-lg-8">
                                                                           <MySelectGetAllDataFromServer
                                                                                name={"saisieManuelleEntite"}
                                                                                url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                                                value={helpers.values.saisieManuelleEntite}
                                                                                onChange={val => helpers.setFieldValue("saisieManuelleEntite", val)}
                                                                                isInvalid={!!helpers.errors.saisieManuelleEntite}
                                                                           />
                                                                           {helpers.errors.saisieManuelleEntite && (
                                                                                <div className="text-danger">{helpers.errors.saisieManuelleEntite}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {/* Field contrat*/}
                                                                 {helpers.values.sousTraitantOuSalarie === "SALARIE" && (
                                                                      <div className={"mb-5"}>
                                                                           <div className="row mb-5">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contrat</label>

                                                                                <div className="col-lg-8">
                                                                                     <MySelectField
                                                                                          name={"saisieManuelleContrat"}
                                                                                          options={[
                                                                                               { label: "CDI C", value: CONST_CONSULTANT_CONTRAT_CDD },
                                                                                               { label: "CDD", value: CONST_CONSULTANT_CONTRAT_CDI },
                                                                                               { label: "CDI", value: CONST_CONSULTANT_CONTRAT_CDIC },
                                                                                          ]}
                                                                                          value={helpers.values.saisieManuelleContrat}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuelleContrat", val)}
                                                                                          isInvalid={!!helpers.errors.saisieManuelleContrat}
                                                                                     />
                                                                                     {helpers.errors.saisieManuelleContrat && (
                                                                                          <div className="text-danger">{helpers.errors.saisieManuelleContrat}</div>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                           <div className="row">
                                                                                <label className="col-lg-4 col-form-label fw-bold fs-6 required">Statut</label>

                                                                                <div className="col-lg-8">
                                                                                     <MySelectField
                                                                                          name={"saisieManuelleStatut"}
                                                                                          options={[
                                                                                               { label: "ETAM", value: CONST_CONSULTANT_STATUT_ETAM },
                                                                                               {
                                                                                                    label: "Agent de maîtrise",
                                                                                                    value: CONST_CONSULTANT_STATUT_AGENT_MAITRISE,
                                                                                               },
                                                                                               { label: "Cadre", value: CONST_CONSULTANT_STATUT_CADRE },
                                                                                          ]}
                                                                                          value={helpers.values.saisieManuelleStatut}
                                                                                          onChange={val => helpers.setFieldValue("saisieManuelleStatut", val)}
                                                                                          isInvalid={!!helpers.errors.saisieManuelleStatut}
                                                                                     />
                                                                                     {helpers.errors.saisieManuelleStatut && (
                                                                                          <div className="text-danger">{helpers.errors.saisieManuelleStatut}</div>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 )}
                                                            </div>
                                                       )
                                                  }

                                                  {((helpers.values.sousTraitantOuSalarie === "SALARIE" && helpers.values.saisieManuelleOuAPartirCandidatExistant) ||
                                                       (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                            helpers.values.typeSousTraitant &&
                                                            helpers.values.fournisseur &&
                                                            helpers.values.fournisseurResp &&
                                                            ((helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                 helpers.values.saisieManuelleOuAPartirCandidatExistant) ||
                                                                 helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE))) && (
                                                       <>
                                                            <div className="separator my-5" />
                                                            <div className={"d-flex justify-content-end gap-5"}>
                                                                 {((helpers.values.sousTraitantOuSalarie === "SALARIE" &&
                                                                      helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                      (helpers.values.sousTraitantOuSalarie === "ST" &&
                                                                           ((helpers.values.typeSousTraitant === CONST_CONSULTANT_STATUT_ST_FREELANCE &&
                                                                                helpers.values.saisieManuelleOuAPartirCandidatExistant === "MANUELLE") ||
                                                                                helpers.values.typeSousTraitant !== CONST_CONSULTANT_STATUT_ST_FREELANCE))) && (
                                                                      <button
                                                                           className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center"}
                                                                           type={"button"}
                                                                           onClick={() => {
                                                                                toast.info("Veuillez rechercher votre candidat")
                                                                                navigate(`recrutement/candidats/search/results`)
                                                                           }}
                                                                           disabled={helpers.isSubmitting}
                                                                      >
                                                                           Demander les informations <i className={"fas fa-envelope ms-1"} />
                                                                      </button>
                                                                 )}
                                                                 <button
                                                                      className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                                      type={"submit"}
                                                                      disabled={helpers.isSubmitting}
                                                                      onClick={() => {
                                                                           if (helpers.values.saisieManuelleOuAPartirCandidatExistant === "EXISTANT") {
                                                                                toast.info("Veuillez rechercher votre candidat")
                                                                                navigate("/recrutement/candidats/search/results")
                                                                           } else {
                                                                                toast.info("Implémentation en cours ...")
                                                                           }
                                                                      }}
                                                                 >
                                                                      {!helpers.isSubmitting ? (
                                                                           <>
                                                                                Démarrer l'embauche <i className={"fas fa-play ms-1"} />
                                                                           </>
                                                                      ) : (
                                                                           <MySimpleSpinner size={"sm"} />
                                                                      )}
                                                                 </button>
                                                            </div>
                                                       </>
                                                  )}
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </MyCard.Body>
                    </MyCard>
               </div>
               {/* Modal add fournisseur */}
               {isAddingFournisseur && (
                    <MyModal title={<>Nouveau fournisseur</>} show={true} handleClose={() => setIsAddingFournisseur(false)}>
                         <AddFournisseurForm callback={() => setIsAddingFournisseur(false)} />
                    </MyModal>
               )}

               {/* Modal add resp client */}
               {isAddingFournisseurResp && (
                    <MyModal title={<>Nouveau responsable fournisseur</>} show={true} handleClose={() => setIsAddingFournisseurResp(false)}>
                         <AddResponsableFournisseurForm callback={() => setIsAddingFournisseurResp(false)} />
                    </MyModal>
               )}
          </>
     )
}

interface IDemandeEmbaucheStartRequestModel {
     sousTraitantOuSalarie?: "SALARIE" | "ST"
     typeSousTraitant?: typeof CONST_CONSULTANT_STATUT_ST_PORTAGE | typeof CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE | typeof CONST_CONSULTANT_STATUT_ST_FREELANCE
     fournisseur?: number
     fournisseurResp?: number
     saisieManuelleOuAPartirCandidatExistant?: "EXISTANT" | "MANUELLE"
     saisieManuelleCivilite?: string
     saisieManuellePrenom?: string
     saisieManuelleNom?: string
     saisieManuelleEmail?: string
     saisieManuelleEntite?: string
     saisieManuelleContrat?: string
     saisieManuelleStatut?: string
}

export default DemandeEmbaucheStart
